<template>
    <!-- <pull-refresh @refresh="refresh" > -->
    <div class="contetn">

        <van-nav-bar title="分配卡片" left-arrow @click-left="onClickLeft" />


        <div class="cardBox">
            <span style="font-size:16px;font-weight:500;">分配卡片给:</span>
            <span style="font-size:16px;color:#1D6CFC;font-weight:500;">{{name}}</span>
            <div style="font-size:12px;color:#999;margin-top:8px;">开始卡片已选中，请选择结束分配卡片范围。</div>
            <div class="centerbox">
                <div class="cardList">
                    <div :class="{'item': selectList.indexOf(item.iccid)== -1 ? false:true,'noActive':selectList.indexOf(item.iccid)== -1 ? true:false}"
                        v-for="(item,index) in list" :key="index" @click="selectItem(item)">{{item.iccid}}</div>
                </div>
                <div style="text-align:center;margin-top:10px;color:#999;" @click="getlist()" v-if="per_page">显示更多<van-icon name="arrow-down" />
                </div>
            </div>
            <div class="selectText">已选中 <span>{{selectList.length}}</span>张卡片 </div>
            <div class="btn" @click="toDistribution()">确认分配</div>
        </div>

    </div>
    <!-- </pull-refresh> -->
</template>
<script>
import { getIccidList ,distributionIccid} from "@/untils/distubritionIndex.js";

import pullRefresh from './components/top.vue'
export default {
    components: {
        pullRefresh
    },
    data() {
        return {
            pages: 0,
            list: [],
            selectList: [],
            name:'',
            per_page:true
        }
    },
    mounted() {
        this.getlist()
       this.name =  this.$route.query.name
        	// 监听 scroll 事件，绑定回调函数
			// window.addEventListener('scroll', this.scrollEvent, true);
    },
    destroyed() {
		    // window.removeEventListener('scroll', this.scrollEvent);
		},
    methods: {
        toDistribution(){
            // 去分配
            let data = {
                allocator_user: JSON.parse(sessionStorage.getItem('UserInfo')).id, // 分配人id
                recipient_user:this.$route.query.id ,//接收人id
                iccidArr:this.selectList //卡片
            }
            console.log(data);
            distributionIccid({data}).then(res=>{
                console.log(res);
                this.$toast(res.data.msg)
                if(res.data.code == 200){
                    this.list = []
                    this.pages = 0
                    this.per_page = true
                    this.getlist()
                }
            })


        },
        selectItem(itm) {
            this.selectList = []
            let indexof = this.list.findIndex((item) => item.iccid == itm.iccid)
            let newArr = this.list.slice(0, ++indexof)
            newArr.forEach((t, i) => {
                this.selectList.push(t.iccid)
            })
        },
        getlist() {
            let data = {
                user_mobile: JSON.parse(sessionStorage.getItem('UserInfo')).mobile,
                page: ++this.pages
            }
            console.log(data);
            getIccidList({ data }).then(res => {
                // this.per_page = res.data.data.data.per_page
                if(res.data.data.data.data.length<36){
                    this.per_page = false
                }
                this.list = [...this.list,...res.data.data.data.data]
                this.selectList[0] = this.list[0].iccid
            })
        },
        onClickLeft() {
            this.$router.go(-1)

         },
        async refresh(done) {
            await this.scarch()
            //请求结束回调
            done()
        },
        scarch() {
            console.log(222);
        }

    }
}

</script>
<style lang="less" scoped>
.contetn {
    min-height: 100vh;

    .cardBox {
        padding: 24px 13px;
        box-sizing: border-box;
        min-height: 80vh;
        .centerbox {
            background-color: #fff;
            border-radius: 10px;
            padding-bottom: 10px;
        }
        .cardList {
            overflow: scroll;
            width: 350px;
            // height: 463px;
            border-radius: 10px;
            background: #FFFFFF;
            margin-top: 10px;
            box-sizing: border-box;
            padding: 17px 10px 0 10px;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;

            .item {
                width: 100px;
                height: 26px;
                border-radius: 13px;
                text-align: center;
                line-height: 26px;
                background-color: #c1d9ff;
                font-size: 10px !important;

                margin-right: 12px;
                margin-bottom: 14px;
                color: #1D6CFC;
            }

            .item:nth-child(3n+3) {
                margin-right: 0;
            }

            .noActive {
                width: 100px;
                height: 26px;
                border-radius: 13px;
                text-align: center;
                line-height: 26px;
                font-size: 10px !important;
                margin-right: 12px;
                margin-bottom: 14px;
                color: #999999;
                background-color: #EFEFEF;
            }

            .noActive:nth-child(3n+3) {
                margin-right: 0;
            }
        }

        .selectText {
            margin-top: 22px;
            margin-bottom: 24px;
            font-size: 16px;
            font-weight: 500;
            color: #333;
            text-align: center;

            span {
                color: #1D6CFC;

            }
        }

        .btn {
            width: 270px;
            height: 43px;
            border-radius: 21px;
            background: #1E6FFF;
            text-align: center;
            line-height: 43px;
            color: #fff;
            font-size: 16px;
            margin: 0 auto;
        }

        .btn:active {
            background: #0c4ab4;

        }
    }
}
</style>